<template>
  <div id="fullpage" ref="fullpage">
    <div class="section" id="section0">
      <div class="section0 hidden">
        <div class="logo"></div>
        <h1>Perfect Landing</h1>
        <h2>
          QUEST and Experience stunning planet adventures! Bet you can't make it
          to Level 5!
        </h2>
        <div class="scroll-down"></div>
      </div>
    </div>
    <!--  -->
    <div class="section" id="section4">
      <div class="section4">
        <div class="app-icon"></div>
        <h3 class="ready-h3">Ready for a fun journey?</h3>
        <div class="ready-text">
          - Super high speed experience caused by strong gravity. Different
          levels have different gravity.
        </div>
        <div class="ready-text">
          - Merge & Idle systems power your landing journey.
        </div>
        <div class="ready-text">
          - Enjoy different abilities of aerobats, some has high-speed, some has
          strong attack and high defense.
        </div>
        <div class="social">
          <router-link to="about-privacy">Privacy Policy</router-link>
          <router-link class="terms" to="about-terms">Terms of Service</router-link>
          <a href="mailto:help@flamezonex.com">help@flamezonex.com</a>
        </div>
        <div class="copyrigth">Made with love</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home.vue",
  created() {
    this.$nextTick(() => {
      $("#fullpage").fullpage({
        navigation: true,
        navigationPosition: "right",
        responsive: 900,
        keyboardScrolling: true,
        onLeave: function(index, newIndex, direction) {
          console.log(index, newIndex, direction);
        },
        afterRender: function () {
          //playing the video
          $("video").trigger("play");
        }
      });
      $(".logo").addClass("animated fadeInUp visible");
      setTimeout(function () {
        $("h1").addClass("animated fadeInUp visible");
      }, 1000);
      setTimeout(function () {
        $("h2").addClass("animated fadeInUp visible");
      }, 2000);
      setTimeout(function () {
        $(".scroll-down").addClass("animated fadeInUp visible");
      }, 2600);
    });
  },
  beforeDestroy() {
    $.fn.fullpage.destroy("all");
  }
};
</script>

<style scoped>
.button11 {
  background-color: #000000;
  border: none;
  color: white;
  padding: 20px 34px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
}
.download11 {
  text-align: center;
}
.section0 h1 {
  text-transform: initial;
}
.ready-h3 {
  margin-bottom: 10px;
}
.ready-text {
  /*text-align: center;*/
  padding: 0;
  font-size: 20px;
  line-height: 28px;
  color: #888888;
  font-weight: 300;
  letter-spacing: 0.2px;
  width: 100%;
  max-width: 520px;
  margin: 5px auto;
}
@media screen and (max-width: 760px) {
  .ready-h3 {
    margin-bottom: 30px;
  }
  .ready-text {
    /*margin-top: 30px;*/
    font-size: 16px;
    width: 100%;
    line-height: 24px;
  }
}
</style>
