<template>
  <div id="app" class="wrapper">
<!--    <a-header></a-header>-->
    <router-view></router-view>
<!--    <a-footer></a-footer>-->
  </div>
</template>

<script>
// import AFooter from "@/views/concept/footer";
// import AHeader from "@/views/adlocked/header";

export default {
  components: {
    // AHeader
    // AFooter
  },
  data() {
    return {
      bottomFixed: false,
      fixedDefault: false
    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.judgeBottomFixed();
    },
  },
  created() {
    this.judgeBottomFixed();
  },
  methods: {
    judgeBottomFixed() {
      const isDailyPage = this.$route.path.includes("daily");
      const isContactPage = this.$route.path.includes("contact");
      this.bottomFixed = isDailyPage;
      this.fixedDefault = isContactPage;
    }
  }
};
</script>
